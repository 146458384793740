import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useFormik } from "formik";
import { yupResolver } from '@hookform/resolvers/yup';
import { verifyAddress } from '../_helpers/verify-address';
import * as Yup from 'yup';
import {
    Form,
    Button,
    Divider,
    Segment,
    Label,
    Header,
    Icon,
    Grid
} from 'semantic-ui-react';

import { 
    alertService, 
    userService,
    customerService
} from '../_services';
import {
    Countries,
    States,
    esUserRoles
} from '../models/Constants'
import { Link } from 'react-router-dom';

function AddEdit({ history, match }) {
    const { id } = match.params;
    const [isAddMode,setIsAddMode] = useState(!id);
    const pathname = window.location.pathname;
    const [isLoading, setLoading] = useState(false);
    const [esUser, setEsUser] = useState([]);

    // View mode
    const [viewMode, setViewMode] = useState(false);
    
    const isRequired = () => ({
        is: "ES_CLINICAL_SITE_MANAGER",
        then: Yup.object().shape({
            street_address_1: Yup.string().required("Street Address 1 is required"),
            street_address_2: Yup.string(),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            postal_code: Yup.string().matches(/^\d{5}$/, 'Zip Code must be 5 digits').required("Zip Code is required"),
            country: Yup.string().required("Country is required")
        })
    });

    // form validation rules
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('First Name is required'),
        last_name: Yup.string()
            .required('Last Name is required'),
        external_user_id: Yup.string()
            .min(6, 'Must be between 6 and 10 digits')
            .max(10, 'Must be between 6 and 10 digits')
            .required('External user ID is required'),
        role_name: Yup.string()
            .required('Role is required'),
        address: Yup.object().shape({
            street_address_1: Yup.string(),
            street_address_2: Yup.string(),
            city: Yup.string(),
            state: Yup.string(),
            postal_code: Yup.string(),
            country: Yup.string()
        }).when("role_name", isRequired()),
        email: Yup.string()
            .required('Email is required'),
        phone_number: Yup.string()
            .max(12, 'Phone number must be no more than 12 digits long')
            .matches(/^(1-)?\d{3}-\d{3}-\d{4}$/, 'Phone number must be in the format xxx-xxx-xxx')
            .required('Phone Number is required')
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            phone_number: "",
            role_name: "",
            external_user_id: "",
            // Role defaults to CLINICAL SITE MANAGER
            
            address: {
                street_address_1: "",
                street_address_2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
            },
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (formData) => {
            onSubmit(formData);
        }
    });

    // functions to build form returned by useForm() hook
    const { setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    async function onSubmit(data) {
        // Strip dashes from numbers
        if(data.role_name != 'ES_CUSTOMER_SERVICE'){
            let verified_address = await verifyAddress(data['address'])
            if (verified_address != null){
                data['address'] = verified_address;
            } else{
                formik.setSubmitting(false);
                return alertService.error('Address is not valid. Please enter a valid address.');
            }
        }
        var submission_data = {...data};
        submission_data["phone_number"] = data["phone_number"].replace(/-/g, "");
        return isAddMode
            ? createEsUser(submission_data)
            : updateEsUser(id, submission_data);

    }

    function createEsUser(data) {
        return userService.create(data)
            .then((response) => {
                alertService.success('Element Science user added', { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function updateEsUser(id, data) {

        return userService.update(id, data)
            .then((response) => {
                alertService.success('Element Science user updated', { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch((err) => {
                formik.setSubmitting(false);
                alertService.error(err);
            });
    }

    function handleUnlockEsUser() {
        const esuser_email = formik.values.email;
        return customerService.unlockAccount({ user_email: esuser_email }).then((response) => {
            alertService.success('Unlocked user account', { keepAfterRouteChange: true });
            history.push('..');
        })
        .catch((err) => {
            alertService.error(err);
        });
    } 
    
    const handleOptionChange = (option, field) => {
        formik.setFieldValue(field, option);
    }

    useEffect(() => {
        if (isAddMode) {
        }
    }, []);

    useEffect(() => {
        if (pathname.includes("view")) {
            setViewMode(true);
        } else {
            setViewMode(false);
        }
    }, [pathname]);


    // Loading wheel for edit/view
    useEffect(() => {
        if (window.onload || isAddMode) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [window]);

    useEffect(() => {
        if (!isAddMode) {
            // get ES user info and set form fields
            userService.getUserById(id)
            .then(esUser => {
                setLoading(false);
                const esUserData = esUser; //esUser.Data;
                formik.setFieldValue("first_name", esUserData.first_name);
                formik.setFieldValue("last_name", esUserData.last_name);
                formik.setFieldValue("external_user_id", esUserData.external_user_id);
                formik.setFieldValue("address.street_address_1", esUserData.address.street_address_1);
                formik.setFieldValue("address.street_address_2", esUserData.address.street_address_2);
                formik.setFieldValue("address.city", esUserData.address.city);
                formik.setFieldValue("address.state", esUserData.address.state);
                formik.setFieldValue("address.postal_code", esUserData.address.postal_code);
                formik.setFieldValue("address.country", esUserData.address.country);                
                formik.setFieldValue("email", esUserData.registration?.email);
                esUserData.phone_number = esUserData.phone_number.slice(0,3)+"-"+esUserData.phone_number.slice(3,6)+"-"+esUserData.phone_number.slice(6);
                formik.setFieldValue("phone_number", esUserData.phone_number);
                formik.setFieldValue("role_name", esUserData.role_name);
            })
            .catch( err => {
                alertService.error(err);
            });
        }
    }, []);
    
    return (     
        <Form className="pl-5" loading={isLoading} onSubmit={formik.handleSubmit}>
        <Header as='h3' style={{paddingTop:'20px', color:'#3F2A57'}}>ES User Details</Header>
            <div className="mt-4">
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="ES User First Name"
                        placeholder="First Name"
                        name="first_name"
                        onChange={formik.handleChange}
                        error={formik.errors.first_name}
                        value={formik.values.first_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="ES User Last Name"
                        placeholder="Last Name"
                        name="last_name"
                        onChange={formik.handleChange}
                        error={formik.errors.last_name}
                        value={formik.values.last_name}
                        width={3}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="ES User ID Number"
                        placeholder="ES User ID Number"
                        name="external_user_id"
                        width={3}
                        onChange={formik.handleChange}
                        error={formik.errors.external_user_id}
                        value={formik.values.external_user_id}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Role"
                        placeholder="ES User Role"
                        name="role_name"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.role_name}
                        value={formik.values.role_name}
                        width={3}
                        options={esUserRoles}
                    />                   
                </Form.Group>
                <Form.Group>
                    <Form.Input 
                        fluid
                        readOnly={viewMode}
                        label="ES User Email"
                        placeholder="Email"
                        name="email"
                        onChange={formik.handleChange}
                        error={formik.errors.email}
                        value={formik.values.email} 
                        width={5}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="ES User Phone"
                        placeholder="xxx-xxx-xxxx"
                        name="phone_number"
                        onChange={formik.handleChange}
                        error={formik.errors.phone_number}
                        value={formik.values.phone_number}
                        width={4}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 1"
                        placeholder="Shipping Address Line 1"
                        name="address.street_address_1"
                        onChange={formik.handleChange}
                        error={formik.errors.address?.street_address_1}
                        value={formik.values.address?.street_address_1}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="City"
                        placeholder="City"
                        name="address.city"
                        onChange={formik.handleChange}
                        error={formik.errors.address?.city}
                        value={formik.values.address?.city}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="State"
                        placeholder="State"
                        name="address.state"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.address?.state}
                        value={formik.values.address?.state}
                        width={2}
                        options={States}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Shipping Address Line 2"
                        placeholder="(Apt, Bldg, Unit)"
                        name="address.street_address_2"
                        onChange={formik.handleChange}
                        error={formik.errors.address?.street_address_2}
                        value={formik.values.address?.street_address_2}
                        width={4}
                    />
                    <Form.Input
                        fluid
                        readOnly={viewMode}
                        label="Zip Code"
                        placeholder="Zip Code"
                        name="address.postal_code"
                        onChange={formik.handleChange}
                        error={formik.errors.address?.postal_code}
                        value={formik.values.address?.postal_code}
                        width={2}
                    />
                    <Form.Select
                        fluid
                        readOnly={viewMode}
                        disabled={viewMode}
                        label="Country"
                        placeholder="Country"
                        name="address.country"
                        onChange={(event, data) => handleOptionChange(data.value, data.name)}
                        error={formik.errors.address?.country}
                        value={formik.values.address?.country}
                        options={Countries}
                        width={2}
                    />
                </Form.Group>
            </div>
                
            <div className="pt-5 mt-5 pl-2 mb-5 pb-5">
                <Form.Group>
                    <span>
                        <>{!formik.isValid?<Label color='red' content={`There are Invalid Fields - Please Update`}/>:<></>}</>
                    </span>
                </Form.Group>
                <Form.Group>
                    <div className="pr-3">
                        <Button 
                            type="submit"
                            primary
                            onSubmit={formik.handleSubmit}
                            disabled={formik.isSubmitting || viewMode || !formik.isValid}  
                        >
                            {formik.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Submit
                        </Button>
                    </div>
                    <Button secondary as={ Link } name='administrators' to='/administrators'>Cancel</Button>
                    <Button
                        className="ml-3"
                        type="button" 
                        name="unlock_button"
                        disabled={!viewMode}
                        onClick={handleUnlockEsUser}
                        >Unlock Account
                    </Button>
                </Form.Group>
            </div>
            
        </Form>
    );
}

export { AddEdit };